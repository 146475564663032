<template>
    <div class="modal secondary offer_popup" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>{{ selectedPlaybook.offer_teaser }}<span>{{ selectedPlaybook.offer_teaser_headline }}</span></h1>
                </div>
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
            </div>
            <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" ref="playbook-offer-popup-form" class="popup-form">
                <div class="modal_body">
                    <div class="setting_wpr">
                        <div class="pop_img">
                            <img :src="selectedPlaybook.offer_popup_image ? selectedPlaybook.offer_popup_image : require('@/assets/images/thumb/default-sm-cover.svg')" />
                        </div>
                        <div class="form_grp" v-if="selectedPlaybook.offer_optin_fields && selectedPlaybook.offer_optin_fields.includes('fname')">
                            <div class="group_item">
                                <label class="input_label">First Name</label>
                                <div class="field_wpr m-0" :class="{ 'has-error': errors.first_name }">
                                    <Field autocomplete="off" name="first_name" type="text" placeholder="ex: John" v-model="form.first_name" rules="required" label="first name" />
                                </div>
                                <ErrorMessage name="first_name" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp" v-if="selectedPlaybook.offer_optin_fields && selectedPlaybook.offer_optin_fields.includes('lname')">
                            <div class="group_item">
                                <label class="input_label">Last Name</label>
                                <div class="field_wpr m-0" :class="{ 'has-error': errors.last_name }">
                                    <Field autocomplete="off" name="last_name" type="text" placeholder="ex: Doe" v-model="form.last_name" rules="required" label="last name" />
                                </div>
                                <ErrorMessage name="last_name" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Email</label>
                                <div class="field_wpr m-0" :class="{ 'has-error': errors.email }">
                                    <Field autocomplete="off" name="email" type="text" placeholder="ex: johndoe@example.com" v-model="form.email" rules="required" />
                                </div>
                                <ErrorMessage name="email" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp" v-if="selectedPlaybook.offer_optin_fields && selectedPlaybook.offer_optin_fields.includes('phone')">
                            <div class="group_item">
                                <label class="input_label">Phone</label>
                                <div class="field_wpr m-0" :class="{ 'has-error': errors.phone }">
                                    <Field autocomplete="off" name="phone" type="text" placeholder="ex: 9876543210" v-model="form.phone" rules="required" />
                                </div>
                                <ErrorMessage name="phone" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp" v-if="selectedPlaybook.offer_optin_fields && selectedPlaybook.offer_optin_fields.includes('address')">
                            <div class="group_item">
                                <label class="input_label">Address</label>
                                <div class="field_wpr m-0" :class="{ 'has-error': errors.address }">
                                    <Field autocomplete="off" name="address" type="text" placeholder="ex: 17155 Newhope St" v-model="form.address" rules="required" />
                                </div>
                                <ErrorMessage name="address" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp" v-if="selectedPlaybook.offer_optin_fields && selectedPlaybook.offer_optin_fields.includes('city')">
                            <div class="group_item">
                                <label class="input_label">City</label>
                                <div class="field_wpr m-0" :class="{ 'has-error': errors.city }">
                                    <Field autocomplete="off" name="city" type="text" placeholder="ex: Fountain Valley" v-model="form.city" rules="required" />
                                </div>
                                <ErrorMessage name="city" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp" v-if="selectedPlaybook.offer_optin_fields && selectedPlaybook.offer_optin_fields.includes('state')">
                            <div class="group_item">
                                <label class="input_label">State/Province</label>
                                <div class="field_wpr m-0" :class="{ 'has-error': errors.state }">
                                    <Field autocomplete="off" name="state" type="text" placeholder="ex: California" v-model="form.state" rules="required" label="state/province" />
                                </div>
                                <ErrorMessage name="state" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp" v-if="selectedPlaybook.offer_optin_fields && selectedPlaybook.offer_optin_fields.includes('zip')">
                            <div class="group_item">
                                <label class="input_label">Zip/Postal Code</label>
                                <div class="field_wpr m-0" :class="{ 'has-error': errors.zip }">
                                    <Field autocomplete="off" name="zip" type="text" placeholder="ex: 92708" v-model="form.zip" rules="required" />
                                </div>
                                <ErrorMessage name="zip" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp" v-if="selectedPlaybook.offer_optin_fields && selectedPlaybook.offer_optin_fields.includes('country')">
                            <div class="group_item">
                                <label class="input_label">Country</label>
                                <div class="field_wpr m-0" :class="{ 'has-error': errors.country }">
                                    <Field autocomplete="off" name="country" v-model="form.country" rules="required">
                                        <multiselect
                                            v-model="form.country"
                                            :options="countries"
                                            value-prop="code"
                                            label="country"
                                            :searchable="true"
                                            placeholder="Select country"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="country" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp" v-if="selectedPlaybook.offer_optin_fields && selectedPlaybook.offer_optin_fields.includes('dob')">
                            <div class="group_item">
                                <label class="input_label">Date of Birth</label>
                                <div class="field_wpr m-0" :class="{ 'has-error': errors.dob }">
                                    <Field autocomplete="off" name="dob" v-model="form.dob" rules="required" label="date of birth">
                                        <datepicker v-model="form.dob" autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                    </Field>
                                </div>
                                <ErrorMessage name="dob" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button class="btn save_btn" :style="`background: ${selectedPlaybook.offer_btn_color}; border-color: ${selectedPlaybook.offer_btn_color}; color: ${selectedPlaybook.offer_btn_txtcolor};`">{{ selectedPlaybook.offer_button_txt }}</button>
                    <p class="text-center">We will not spam, rent or sell your information</p>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import Multiselect from '@vueform/multiselect'

    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState } from 'vuex'

    export default {
        name: 'Playbook Offer Popup',

        data () {
            return {
                form: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    address: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: '',
                    dob: '',
                },
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            Multiselect
        },

        watch: {
            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.resetForm();
                }
            },
        },

        computed: mapState({
            selectedPlaybook: state => state.playbookModule.selectedPlaybook,
            countries: state => state.commonModule.countries,
            user: state => state.authModule.user,
        }),


        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    first_name: vm.user.fname,
                    last_name: vm.user.lname,
                    email: vm.user.email,
                    phone: vm.user.mobileno,
                    address: vm.user.address,
                    city: vm.user.city,
                    state: vm.user.state,
                    zip: vm.user.zip,
                    country: vm.user.country,
                    dob: '',
                };
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;

                vm.closeModal();
            },
        },
    }
</script>

<style scoped>
    .offer_popup .modal_container{
        padding: 0;
        align-items: stretch;
        border-radius: 8px;
    }
    .offer_popup .modal_header{
        padding: 0 35px;
    }
    .offer_popup .modal_header h1{
        margin: 15px 0;
    }
    .offer_popup .popup-form{
        max-height: 550px;
        height: auto;
    }
    .offer_popup .modal_body{
        padding: 20px 35px;
        overflow-y: overlay;
    }
    .offer_popup .pop_img{
        line-height: 0;
        margin-bottom: 10px;
    }
    .offer_popup .pop_img img{
        width: 100%;
        height: auto;
        border-radius: 5px;
    }
    .offer_popup .modal_footer{
        padding: 20px 35px;
        flex-direction: column;
        align-items: stretch;
        text-align: center;
        border-radius: 0 0 8px 8px;
    }
    .offer_popup .modal_footer .primary_btn{
        height: 45px;
    }
    .offer_popup .modal_footer p{
        font-size: 13px;
        line-height: 17px;
        margin-top: 10px;
    }
    .popup-form {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 99px);
    }

    .modal_footer {
        flex-direction: column;
    }

    .save_btn {
        width: 100%;
    }
</style>

